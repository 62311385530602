(function ($) {
	"use strict";
    var Brw_Frontend = { 
       	init: function () {
       		this.brw_datepicker();
            this.submit_button();
            this.ova_collapsed();
            this.ova_field_search_changed();
            this.ova_get_time_to_book_start();
            this.ova_get_time_to_book_end();
            this.ova_startdate_perido_time();
            this.ova_ajax_load_packages();
            this.ova_ajax_show_total();
            this.ova_ajax_show_total_after_load();
            this.ova_search_ajax();
            this.ova_gallery_product();
            this.ova_deposit();
            this.ova_remove_from_cart();
        },

        ova_get_time_to_book_start: function( time_to_book ) {
            var time_to_book_arr        = [];
            var time_to_book_general    = [];

            if ( typeof brw_time_to_book_general_calendar !== 'undefined' ) {
                time_to_book_general =  brw_time_to_book_general_calendar.replace(/ /g,'').split( ',' );
            }

            if ( typeof time_to_book == 'undefined' ) {
                time_to_book_arr = time_to_book_general;
            } else if ( time_to_book == 'no' ) {
                time_to_book_arr = [];
            } else {
                time_to_book_arr =  time_to_book.replace(/ /g,'').split( ',' );
            }

            return time_to_book_arr;
        },

        ova_get_time_to_book_end: function( time_to_book ) {
            var time_to_book_arr            = []; 
            var time_to_book_endate_general = [];

            if ( typeof brw_time_to_book_general_calendar_endate !== 'undefined' ) {
                time_to_book_endate_general =  brw_time_to_book_general_calendar_endate.replace(/ /g,'').split( ',' );
            }

            if ( typeof time_to_book == 'undefined' ) {
                time_to_book_arr = time_to_book_endate_general;
            } else if ( time_to_book == 'no' ) {
                time_to_book_arr = [];
            } else {
                time_to_book_arr =  time_to_book.replace(/ /g,'').split( ',' );
            }

            return time_to_book_arr;
        },

        ova_field_search_changed: function() {
            /* Attribute Changed */
            $('.ovabrw_search select[name="ovabrw_attribute"]').on('change', function() {
                $(this).closest('.wrap_content').children('.s_field.ovabrw-value-attribute').css('display', 'none');

                var val = $(this).val();

                if ( val ){
                    $(this).closest('.wrap_content').children('.s_field#' + val).css('display', 'inline-block');    
                }
            });

            /* Cat Changed */
            $('.ovabrw_search select[name="cat"]').on('change', function() {
                var form    = $(this).closest('form');
                var cat_val = $(this).val();

                ovas_show_cus_tax( form, cat_val );
            });

            if (  $('.ovabrw_search').length ){
                ovas_show_cus_tax(  $('.ovabrw_search'), '' );
            }
            
            /* Cat when the page loaded*/
            if ( $( '.ovabrw_search select[name="cat"]' ).length ) {
                var form    = $('form.ovabrw_search');
                var cat_val = $( 'form.ovabrw_search select[name="cat"]' ).val();

                if ( cat_val ) {
                    ovas_show_cus_tax( form, cat_val );    
                }
            }
        },

        brw_datepicker: function() {
            // get firstday
            var firstday = $('.ovabrw_datetimepicker').data('firstday');

            /* Calendar language */
            if ( typeof brw_lang_general_calendar !== 'undefined' ) {
                $.datetimepicker.setLocale( brw_lang_general_calendar );
            }

            var disweek_arr = '';
            if ( typeof brw_disable_week_day !== 'undefined' ) {
                disweek_arr = brw_disable_week_day.split(',').map( function(item) {
                    return parseInt(item, 10);
                });
            }

            var allowTimes = '';
            if ( typeof brw_time_to_book_general_calendar !== 'undefined' ) {
                allowTimes =  brw_time_to_book_general_calendar.replace(/ /g,'').split( ',' );
            }

            var data_step = 30;
            if ( typeof brw_data_step !== 'undefined' ) {
                data_step = brw_data_step;
            }

            var time_format = '';
            if ( typeof brw_format_time !== 'undefined' ) {
                time_format = brw_format_time;
            }
            
            var date_format = '';
            if ( typeof brw_date_format !== 'undefined' ) {
                date_format = brw_date_format;
            }

            switch ( date_format ) {
                case 'd-m-Y':
                    date_format = 'DD-MM-Y';
                    break;
                case 'm/d/Y':
                    date_format = 'MM/DD/Y';
                    break;
                case 'Y/m/d':
                    date_format = 'Y/MM/DD';
                    break;
                case 'Y-m-d':
                    date_format = 'Y-MM-DD';
                    break;
                default:
                    date_format = 'DD-MM-Y';
            }
            
            var today = new Date();

            // Setup Mask date for datetimepicker via moment library
            $.datetimepicker.setDateFormatter({
                parseDate: function ( date, format ) {
                    var d = moment(date, format);
                    return d.isValid() ? d.toDate() : false;
                },
                
                formatDate: function ( date, format ) {
                    return moment(date).format(format);
                },
            });

            // Check mobile
            if ( $(window).width() <= 1024 ) {
                $('.ovabrw_datetimepicker').datetimepicker({closeOnTimeSelect: false}); // Not closed when selecting time.
            }

            $('.ovabrw_datetimepicker').each( function() {
                /* Disable Date */
                var disabledDates       = [];
                var order_time_arr_new  = [];
                var order_time          = $(this).attr( 'order_time' );

                var product_disable_week_day = $(this).data('disable-week-day');
                if ( product_disable_week_day ) {
                    disweek_arr = product_disable_week_day.toString().split(',').map(function(item) {
                        return parseInt(item, 10);
                    });
                }

                if ( order_time ) {
                    order_time_arr_new = JSON.parse( order_time );
                }
               
                order_time_arr_new.forEach( function(item, index) {
                    if ( item.hasOwnProperty('rendering') ) {
                        if ( item.start_v2 ) {
                            disabledDates.push(item.start_v2);
                        }
                    }
                });

                // Default Hour Start
                var defaultTime = $(this).attr('default_hour');

                var datePickerOptions = {
                    scrollInput: false,
                    dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    format: date_format+' '+time_format,
                    formatDate: date_format,
                    formatTime:time_format,
                    step: data_step, 
                    allowTimes: allowTimes,
                    timepicker:true,
                    autoclose: true,
                    disabledDates: disabledDates,
                    defaultTime: defaultTime,
                }

                if ( $(this).hasClass('no_time_picker') ) {
                    datePickerOptions = {
                        scrollInput: false,
                        dayOfWeekStart: firstday,
                        minDate: today,
                        disabledWeekDays: disweek_arr,
                        format: date_format,
                        formatDate: date_format,
                        formatTime:time_format,
                        step: data_step, 
                        allowTimes: allowTimes,
                        timepicker:true,
                        autoclose: true,
                        disabledDates: disabledDates,
                        defaultTime: defaultTime
                    };
                }

                $(this).datetimepicker(datePickerOptions);
            });

        	$('.ovabrw_datetimepicker.ovabrw_start_date').on('click', function() {
                /* Disable Date */
                var disabledDates       = [];
                var order_time_arr_new  = [];
                var order_time          = $(this).attr( 'order_time' );

                var product_disable_week_day = $(this).data('disable-week-day');
                if ( product_disable_week_day ) {
                    disweek_arr = product_disable_week_day.toString().split(',').map(function(item) {
                        return parseInt(item, 10);
                    });
                }

                if ( order_time ) {
                    order_time_arr_new = JSON.parse( order_time );
                }
               
                order_time_arr_new.forEach( function(item, index) {
                    if ( item.hasOwnProperty('rendering') ) {
                        if ( item.start_v2 ) {
                            disabledDates.push(item.start_v2);
                        }
                    }
                });
                
                // Default Hour Start
                var defaultTime = $(this).attr('default_hour');

                var time_format = '';
                if ( typeof brw_format_time !== 'undefined' ) {
                    time_format = brw_format_time;    
                }

                var timepicker = $(this).attr('timepicker');
                if ( timepicker == 'true' ) {
                    timepicker = true;
                } else {
                    timepicker = false;
                }

                var time_to_book    = $(this).attr('time_to_book');
                var allowTimes      = Brw_Frontend.ova_get_time_to_book_start(time_to_book);

        		var datePickerOptions = {
    				dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    step: data_step,
                    format: date_format+' '+time_format,    
                    formatDate: date_format,
                    formatTime: time_format,
                    defaultTime: defaultTime,
                    allowTimes: allowTimes,
                	timepicker: timepicker,
                    disabledDates: disabledDates,
                };

                if ( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                    datePickerOptions = {
                        dayOfWeekStart: firstday,
                        minDate: today,
                        disabledWeekDays: disweek_arr,
                        autoclose: true,
                        step: data_step,
                        format: date_format,    
                        formatDate: date_format,
                        defaultTime: false,
                        allowTimes: allowTimes,
                        timepicker: false,
                        disabledDates: disabledDates,
                    };

                    var checkoutDate = $(this).closest('form').find('input[name="ovabrw_dropoff_date"]');
                    if ( checkoutDate.length > 0 ) {
                        checkoutDate.val('');
                    }
                }

                $(this).datetimepicker(datePickerOptions);
            });

            $('.ovabrw_datetimepicker.ovabrw_end_date').on('click',function() {
                /* Disable Date */
                var disabledDates       = [];
                var order_time_arr_new  = [];
                var order_time          = $(this).attr( 'order_time' );

                var product_disable_week_day = $(this).data('disable-week-day');
                if ( product_disable_week_day ) {
                    disweek_arr = product_disable_week_day.toString().split(',').map(function(item) {
                        return parseInt(item, 10);
                    });
                }

                if ( order_time ) {
                    order_time_arr_new = JSON.parse( order_time );
                }
               
                order_time_arr_new.forEach(function(item, index) {
                    if ( item.hasOwnProperty('rendering') ) {
                        if ( item.start_v2 ) {
                            disabledDates.push(item.start_v2);
                        }
                    }
                });

                var timepicker = $(this).attr('timepicker');
                if ( timepicker == 'true' ) {
                    timepicker = true;
                } else {
                    timepicker = false;
                }

                var time_format = '';
                if ( typeof brw_format_time !== 'undefined' ) {
                    time_format = brw_format_time;    
                }
                
                var time_to_book    = $(this).attr('time_to_book');
                var allowTimes      = Brw_Frontend.ova_get_time_to_book_end(time_to_book);
                var defaultTime     = $(this).attr('default_hour');

                if ( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                    timepicker  = false;
                    time_format = '';
                    defaultTime = false;
                } 

                /* Get Min Time */
                var min = $(this).closest('form').find('.ovabrw_start_date').val();
                if ( ! min ) {
                    min = $('.ovabrw_start_date.ovabrw_datetimepicker').val();
                }

                var datePickerOptions = {
                    dayOfWeekStart: firstday,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    step: data_step,
                    minDate: min ? min : false,
                    format: date_format+' '+time_format,
                    formatDate: date_format,
                    formatTime:time_format,
                    defaultTime: defaultTime,
                    allowTimes: allowTimes,
                    timepicker:timepicker,
                    disabledDates: disabledDates,
                }

                if ( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                    datePickerOptions = {
                        dayOfWeekStart: firstday,
                        disabledWeekDays: disweek_arr,
                        autoclose: true,
                        step: data_step,
                        minDate: min ? min : false,
                        format: date_format,
                        formatDate: date_format,
                        defaultTime: false,
                        allowTimes: allowTimes,
                        timepicker: false,
                        disabledDates: disabledDates,
                    };

                    // Check-out date always greater that check-in date
                    if ( typeof checkoutGreaterThancheckin !== 'undefined' && checkoutGreaterThancheckin ) {
                        var startDate = $(this).closest('form').find('input[name="ovabrw_pickup_date"]').val();

                        if ( startDate ) {
                            var minDate = getConvertDate( startDate, date_format );
                            minDate.setDate(minDate.getDate() + 1);
                            minDate = moment(minDate).format(date_format);
                            datePickerOptions['minDate']    = minDate;
                            datePickerOptions['startDate']  = minDate;
                        }
                    }
                }

                $(this).datetimepicker(datePickerOptions);
            });

            //date picker period (date, no time)
            $('.ovabrw_datetimepicker.date_picker_period').each( function() {
                var hour_default    = $(this).data('hour_default');
                var time_step       = $(this).data('time_step');         

                var datePickerOptions = {
                    dayOfWeekStart: firstday,
                    minDate: today,
                    format: date_format,
                    timepicker: false,
                }

                $(this).datetimepicker(datePickerOptions);
            });

            $('.ovabrw_datetimepicker.no_time_picker').each(function(){
                var datePickerOptions = {
                    timepicker:false,
                }

                $(this).datetimepicker(datePickerOptions);
            });


            // Convert Date
            function getConvertDate( dateString = '', dateFormat = 'DD-MM-Y' ) {
                if ( dateString ) {
                    if ( dateFormat === 'DD-MM-Y' ) {
                        var parts       = dateString.split("-");
                        var year        = parseInt(parts[2], 10);
                        var month       = parseInt(parts[1], 10) - 1;
                        var day         = parseInt(parts[0], 10);
                        return new Date(year, month, day);
                    }

                    var year, month, day;

                    switch ( dateFormat ) {
                        case 'DD-MM-Y':
                            var parts = dateString.split("-");
                            year    = parseInt(parts[2], 10);
                            month   = parseInt(parts[1], 10) - 1;
                            day     = parseInt(parts[0], 10);
                            break;
                        case 'MM/DD/Y':
                            var parts = dateString.split("/");
                            year    = parseInt(parts[2], 10);
                            month   = parseInt(parts[0], 10) - 1;
                            day     = parseInt(parts[1], 10);
                            break;
                        case 'Y/MM/DD':
                            var parts = dateString.split("/");
                            year    = parseInt(parts[0], 10);
                            month   = parseInt(parts[1], 10) - 1;
                            day     = parseInt(parts[2], 10);
                            break;
                        case 'Y-MM-DD':
                            var parts = dateString.split("-");
                            year    = parseInt(parts[0], 10);
                            month   = parseInt(parts[1], 10) - 1;
                            day     = parseInt(parts[2], 10);
                            break;
                        default:
                            break;
                    }

                    return new Date(year, month, day);
                }

                return dateString;
            }
        },

        submit_button: function() {
        	$('.ovabrw_btn_submit').on('click', function(e) {
    			var content_required = $(this).closest('.ovabrw_search').data('mesg_required');
    			var flag = true;

    			$(this).closest('.ovabrw_search').find('.content .wrap-error').empty();

                var class_require = $(this).closest('.form_ovabrw').find('.required');
                class_require.each(function(){
    				if ( ! $(this).val() ) {
    					flag = false;
    					$(this).parent('.content').children('.wrap-error').append('<p class="error">'+content_required+'</p>');
    				}
    			});

    			if ( ! flag ) {
    				e.preventDefault();
    				return false;
    			}
    		});

            /* Booking Form submit */
            $('.ovabrw_booking_form button.submit').on('click', function(e) {
                var flag = true;
                $(this).closest('.ovabrw_booking_form').find('.error_item').css('display', 'none');

                $(this).closest('.ovabrw_booking_form').find('.required').each( function() {
                    if ( ! $(this).val() ) {
                        flag = false;
                        $(this).closest('.rental_item').find('.error_item').css('display', 'block');

                        if( $(this).parent('.period_package').length > 0 ) {
                            $(this).parent('.period_package').parent('.rental_item').children('.error_item').css('display', 'block');
                        }
                    }

                    var type = $(this).attr('type');

                    if ( type == 'checkbox' ) {
                        var val = $(this).closest('.rental_item').find('input:checked').val();

                        if ( ! val ) {
                            flag = false;
                            $(this).closest('.rental_item').find('.error_item').css('display', 'block');
                        }
                    }
                });

                if ( ! flag ) {
                    e.preventDefault();
                } 
            });

            /* Request Form submit */
            $('.request_booking button.submit').on('click', function(e){
                var flag = true;
                $(this).closest('.request_booking').find('.error_item').css('display', 'none');

                $(this).closest('.request_booking').find('.required').each( function() {
                    if ( ! $(this).val() ) {
                        flag = false;

                        $(this).closest('.rental_item').children('.error_item').css('display', 'block');

                        if ( $(this).parent('.period_package').length > 0 ) {
                            $(this).parent('.period_package').parent('.rental_item').children('.error_item').css('display', 'block');
                        }
                    }

                    var type = $(this).attr('type');

                    if ( type == 'checkbox' ) {
                        var val = $(this).closest('.rental_item').find('input:checked').val();

                        if ( ! val ) {
                            flag = false;
                            $(this).closest('.rental_item').find('.error_item').css('display', 'block');
                        }
                    }
                });

                if ( ! flag ) {
                    e.preventDefault();
                }
            });

            /* File */
            $('.ovabrw-file input[type="file"]').each( function() {
                $(this).on( 'change', function(e) {
                    e.preventDefault();
                    var file            = $(this);
                    var limit           = file.data('max-file-size');
                    var max_size_msg    = file.data('max-file-size-msg');
                    var formats_msg     = file.data('formats');
                    var file_mimes      = file.data('file-mimes');
                    var formats_file    = [];

                    if ( typeof( file_mimes ) == 'object' ) {
                        $.each( file_mimes, function( key, val ) {
                            formats_file.push( val );
                        });
                    }

                    file.closest('.ovabrw-file').find('.ovabrw-file-name').html('')
                    
                    var name = this.files[0].name;
                    var size = this.files[0].size;
                    var type = this.files[0].type;
                    
                    if ( name && size && type ) {
                        file.closest('.ovabrw-file').find('.ovabrw-file-name').html(name);

                        var mb = ( size/1048576 ).toFixed(2);

                        if ( mb > limit ) {
                            file.closest('.ovabrw-file').find('.ovabrw-file-name').html(max_size_msg);
                        }

                        if ( $.inArray( type, formats_file ) == -1 ) {
                            file.closest('.ovabrw-file').find('.ovabrw-file-name').html(formats_msg);
                        }
                    }
                });
            });
        },

        ova_collapsed: function() {
            $('.ovabrw-according').off().on('click', function(e) {
                e.preventDefault();
                $(this).siblings('.ovabrw_collapse_content').slideToggle();
            });

            //open popup
            $('.ovabrw_open_popup').off().on('click', function(e) {
                e.preventDefault();
                $(this).siblings('.popup').css('display', 'block');
            });

            //close popup
            $('.popup-close').on('click', function(e) {
                e.preventDefault();
                $(this).parent('.popup-inner').parent('.popup').css('display', 'none');
            });

            $('.popup-close-2').on('click', function(e) {
                e.preventDefault();
                $(this).parent('.close_discount').parent('.popup-inner').parent('.popup').css('display', 'none');
            });

            // close room video popup
            $(window).click( function(e) {
                if ( e.target.className == 'room-video-modal-container' ) {
                    var modal_container = $(document).find('.elementor-product-imagex .room-video-modal-container');
                    modal_container.hide();
                }
            });
        },

        ova_startdate_perido_time: function() {
            $('.ovabrw_datetimepicker.startdate_perido_time').focus(function(e) {
                $(this).blur();
            });
            
            /* Choose pickup date in period time */
            $( 'body' ).on( 'change', '.startdate_perido_time', function() {
                var that = $(this);
                if ( that.val() ) {
                    that.closest('form').find( '.show_ajax_content' ).removeClass('active');
                    that.closest('form').find( '.show_total' ).html('');
                    Brw_Frontend.ova_ajax_load_packages(that);
                }
            });
        },

        ova_ajax_load_packages: function( that = null ) {
            if ( that != null ) {
                var startdate = that.val();
                var post_id = that.data('pid');

                that.closest('form').find( 'select[name="ovabrw_period_package_id"]' ).attr( 'disabled', 'disabled' );

                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: ({
                        action: 'ovabrw_get_package_by_time',
                        startdate: startdate,
                        post_id: post_id
                    }),
                    success: function(response) {
                        that.closest('form').find( 'select[name="ovabrw_period_package_id"] option ' ).remove();

                        var option          = '';
                        var option_obj = $.parseJSON( response );

                        if ( option_obj ) {
                            $.each(option_obj, function(key, value) {
                                option += '<option value="'+ key + '">' + value + '</option>';  
                            });
                        }

                        if ( option ) {
                            that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).append(option);
                        }

                        if ( that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).hasClass("select2-hidden-accessible") ) {
                            that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).select2();
                        }

                        that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).prop("disabled", false);
                        that.closest('form').find( '.show_ajax_content' ).removeClass('active');
                        that.closest('form').find( '.show_total' ).html('');
                    },
                });
            }
        },

        ova_ajax_show_total: function(){
            $( 'body' ).on( 'change', '.booking_form', function(){
                var pickup_date = $(this).find('input[name="ovabrw_pickup_date"]').val();

                if ( pickup_date ) {
                    Brw_Frontend.ova_ajax_show_total_after_load($(this));
                }
            });

            $('.booking_form').each( function() {
                var that = $(this);
                var run_ajax = that.data('run_ajax');

                if ( run_ajax != false ) {
                    var check_in    = that.find('input[name="ovabrw_pickup_date"]').val();
                    var check_out   = that.find('input[name="ovabrw_dropoff_date"]').val();
                
                    if ( check_in && check_out ) {
                        Brw_Frontend.ova_ajax_show_total_after_load(that);
                    }
                }
            });
        },

        ova_ajax_show_total_after_load: function( that = null ) {
            var pickup_loc, dropoff_loc, pickup_date, dropoff_date, quantity, resources, services, id, package_id, deposit;
            var resources = [];
            var services = [];
            var obj_resource, obj_service;
            var custom_ckf = {};

            if ( that != null ) {
                var current_form = that;

                current_form.find( '.ajax_loading' ).addClass('active');
                
                if ( current_form.find('input[name="car_id"]').val() ) {
                    id = current_form.find('input[name="car_id"]').val();
                }

                if ( current_form.find('select[name="ovabrw_pickup_loc"]').val() ) {
                    pickup_loc = current_form.find('select[name="ovabrw_pickup_loc"]').val();
                }

                if ( current_form.find('select[name="ovabrw_pickoff_loc"]').val() ) {
                    dropoff_loc = current_form.find('select[name="ovabrw_pickoff_loc"]').val();
                }

                if ( current_form.find('input[name="ovabrw_pickup_date"]').val() ) {
                    pickup_date = current_form.find('input[name="ovabrw_pickup_date"]').val();
                }

                if ( current_form.find('input[name="ovabrw_dropoff_date"]').val() ) {
                    dropoff_date = current_form.find('input[name="ovabrw_dropoff_date"]').val();
                }

                if ( current_form.find('select[name="ovabrw_period_package_id"]').val() ) {
                    package_id = current_form.find('select[name="ovabrw_period_package_id"]').val();
                }
                
                if ( current_form.find('input[name="ovabrw_number_vehicle"]').val() ) {
                    quantity = current_form.find('input[name="ovabrw_number_vehicle"]').val();
                }

                if ( current_form.find('input[name="ova_type_deposit"]:checked').val() ) {
                    deposit = current_form.find('input[name="ova_type_deposit"]:checked').val();
                }

                if ( current_form.find('input[name="data_custom_ckf"]') ) {
                    var ckf = current_form.find('input[name="data_custom_ckf"]').data('ckf');
                    
                    if ( ckf ) {
                        $.each( ckf, function( key, obj ) {
                            if ( obj.type == 'radio' ) {
                                custom_ckf[key] = current_form.find('input[name="'+key+'"]:checked').val();
                            }
                            if ( obj.type == 'checkbox' ) {
                                var ob_checkbox = [];
                                current_form.find(".ovabrw-checkbox input[type=checkbox]:checked").each(function () {
                                    ob_checkbox.push($(this).val());
                                });

                                custom_ckf[key] = ob_checkbox;
                            }
                            if ( obj.type == 'select' ) {
                                custom_ckf[key] = current_form.find('select[name="'+key+'"]').val();
                            }
                        });
                    }
                }

                // Reference the CheckBoxes and insert the checked CheckBox value in Array.
                current_form.find(".ovabrw_resource input[type=checkbox]:checked").each(function () {
                    resources[$(this).data('resource_key')] = $(this).val();
                });
                
                // Convert to object
                obj_resource = $.extend({}, resources ) ;

                // Service
                current_form.find( "select[name='ovabrw_service[]']" ).each( function() {
                    if ( $(this).val() ) {
                        services.push($(this).val());    
                    }
                });
              
                obj_service = $.extend({}, services ) ;
            
                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: ({
                        action: 'ovabrw_calculate_total',
                        pickup_loc: pickup_loc,
                        dropoff_loc: dropoff_loc,
                        pickup_date: pickup_date,
                        package_id: package_id,
                        dropoff_date: dropoff_date,
                        quantity: quantity,
                        deposit: deposit,
                        resources: JSON.stringify( obj_resource ),
                        services: JSON.stringify( obj_service ),
                        custom_ckf: JSON.stringify( custom_ckf ),
                        id: id,
                    }),
                    success: function(response){
                        var data = JSON.parse(response);

                        if ( response != 0 ) {
                            that.find( '.show_ajax_content' ).addClass('active');
                            that.find( '.show_total' ).html('').append( data['line_total'] );
                            if ( data['error'] ) {
                                that.find( '.show_ajax_content' ).removeClass('active');
                                that.find( '.show_total' ).html('');
                                that.find( '.ajax-show-error' ).html('').append( data['error'] ).show();
                            } else if ( data['number_vehicle_available'] > 0 ) {
                                that.find( '.show_availables_vehicle' ).html('').append( data['number_vehicle_available'] );
                                that.find( 'input[name="ovabrw_number_vehicle"]' ).attr('max', data['number_vehicle_available']);
                                that.find( '.ajax-show-error' ).html('').hide();
                            } else {
                                that.find( '.show_availables_vehicle' ).html('').append( 0 );
                                that.find( 'input[name="ovabrw_number_vehicle"]' ).attr('max', 1);
                                that.find( '.ajax-show-error' ).html('').hide();
                            }
                        } else {
                            that.find( '.show_ajax_content' ).removeClass('active');
                            that.find( '.show_total' ).html('');
                            that.find( '.ajax-show-error' ).html('').hide();
                        }

                        current_form.find( '.ajax_loading' ).removeClass('active');
                    },
                });
            }
        },

        ova_search_ajax: function() {

            if ( $('.ovabrw-search-ajax .wrap-search-ajax').length > 0 ) {
                var clicked = $('.ovabrw-search-ajax .wrap-search-ajax').data('clicked');
                 
                if ( clicked ) {
                    loadAjaxSearch( true );
                } else {
                    loadAjaxSearch();
                }
            }

            $('.ovabrw-search-ajax .wrap-search-ajax .ovabrw-btn').on('click', function(e){
                loadAjaxSearch( true );

                $('html, body').animate({
                    scrollTop: $("#search-ajax-result").offset().top - 250
                }, 500);

                // hide filter sort by dropdown 
                $(this).closest('.wrap-search-ajax').find('.input_select_list').hide();

                e.preventDefault();
            });

            /* Result Layout */
            $('.ovabrw-search-ajax').on('click', '.wrap-search-ajax .filter-layout' , function(e) {
                e.preventDefault();

                var that          = $(this);
                var layout_active = $('.wrap-search-ajax .filter-layout-active').attr('data-layout');
                var layout        = that.attr('data-layout');
                var clicked       = that.closest('.wrap-search-ajax').find('.ovabrw-room-ajax').data('clicked');

                if ( layout != layout_active ) {
                    $('.wrap-search-ajax .filter-layout').removeClass('filter-layout-active');
                    that.addClass('filter-layout-active');

                    if ( clicked ) {
                        loadAjaxSearch( true );
                    } else {
                        loadAjaxSearch();
                    }
                }
            });

            /* Sort by */
            $('.ovabrw-search-ajax').on('click', '.wrap-search-ajax .ovabrw-room-filter .input_select_list .term_item' , function(e) {
                e.preventDefault();

                var that          = $(this);
                var sort_by_value = that.closest('.filter-sort').find('.input_select_input_value').val();
                var search_result = that.closest('.wrap-search-ajax').find('.search-ajax-result');
                var clicked       = that.closest('.wrap-search-ajax').find('.ovabrw-room-ajax').data('clicked');

                if( sort_by_value == 'id_desc') {
                    search_result.data('order','DESC');
                    search_result.data('orderby','ID');
                    search_result.data('orderby_meta_key','');
                } else if( sort_by_value == 'rating_desc' ) {
                    search_result.data('order','DESC');
                    search_result.data('orderby','meta_value_num');
                    search_result.data('orderby_meta_key','_wc_average_rating');
                } else if( sort_by_value == 'price_asc' ) {
                    search_result.data('order','ASC');
                    search_result.data('orderby','meta_value_num');
                    search_result.data('orderby_meta_key','_price');
                } else if( sort_by_value == 'price_desc' ) {
                    search_result.data('order','DESC');
                    search_result.data('orderby','meta_value_num');
                    search_result.data('orderby_meta_key','_price');
                }

                if ( clicked ) {
                    loadAjaxSearch( true );
                } else {
                    loadAjaxSearch();
                }
                
            });

            // Event click clear filter
            $(".ovabrw-room-filter .clear-filter").on( "click", function(e) {
                e.preventDefault();
                var clear_btn       = $(this);
                var wrap_search     = clear_btn.closest('.wrap-search-ajax');
                var adults          = wrap_search.data('adults');
                var childrens       = wrap_search.data('childrens');
                var beds            = wrap_search.data('beds');
                var baths           = wrap_search.data('baths');

                //reset data-paged
                clear_btn.closest('.wrap-search-ajax').find('.ovabrw-pagination-ajax').attr('data-paged', 1);

                if ( typeof adults === "undefined" || ! adults ) {
                    adults = 1;
                }

                if ( typeof childrens === "undefined" || ! childrens ) {
                    childrens = 0;
                }

                if ( typeof beds === "undefined" || ! beds ) {
                    beds = 1;
                }

                if ( typeof baths === "undefined" || ! baths ) {
                    baths = 1;
                }

                // reset all input search bar
                wrap_search.find('input[name="ovabrw_pickup_date"]').val('').trigger("change");
                wrap_search.find('input[name="ovabrw_dropoff_date"]').val('').trigger("change");
                wrap_search.find('input[name="ovabrw_adults"]').val(adults);
                wrap_search.find('input[name="ovabrw_childrens"]').val(childrens);
                wrap_search.find('input[name="ovabrw_beds"]').val(beds);
                wrap_search.find('input[name="ovabrw_baths"]').val(baths);   

                loadAjaxSearch();
            });

            /* Pagination */
            $(document).on('click', '.wrap-search-ajax .ovabrw-pagination-ajax .page-numbers', function(e) {
                e.preventDefault();

                var that    = $(this);
                var current = $('.wrap-search-ajax .ovabrw-pagination-ajax .current').attr('data-paged');
                var paged   = that.attr('data-paged');
                var clicked = that.closest('.wrap-search-ajax').find('.ovabrw-room-ajax').data('clicked');

                if ( (current != paged) || (current == 1 && paged == 1) ) {
                    $(window).scrollTop(0);
                    $('.wrap-search-ajax .ovabrw-pagination-ajax .page-numbers').removeClass('current');
                    that.addClass('current');

                    if ( clicked ) {
                        loadAjaxSearch( true );
                    } else {
                        loadAjaxSearch();
                    }
                }

            });

            /* load ajax search */
            function loadAjaxSearch( clicked = null ) {
                var that        = $(document).find('.ovabrw-search-ajax .wrap-search-ajax');
                var template    = that.find('.filter-layout-active').attr('data-layout');
                var start_date  = that.find('input[name="ovabrw_pickup_date"]').val();
                var end_date    = that.find('input[name="ovabrw_dropoff_date"]').val();
                var adults      = that.find('input[name="ovabrw_adults"]').val();
                var childrens   = that.find('input[name="ovabrw_childrens"]').val();
                var beds        = that.find('input[name="ovabrw_beds"]').val();
                var baths       = that.find('input[name="ovabrw_baths"]').val();
                var result      = that.find('.search-ajax-result');

                var icon_adults   = that.data('icon_adults');
                var icon_children = that.data('icon_children');
                var icon_area     = that.data('icon_area');
                var icon_beds     = that.data('icon_beds');
                var icon_baths    = that.data('icon_baths');

                // Meta fields
                var field_1       = that.data('field_1');
                var field_2       = that.data('field_2');
                var field_3       = that.data('field_3');
                var field_4       = that.data('field_4');
                var field_5       = that.data('field_5');

                var order       = result.data('order');
                var orderby     = result.data('orderby');
                var per_page    = result.data('posts-per-page');
                var paged       = result.find('.ovabrw-pagination-ajax .current').attr('data-paged');

                var orderby_meta_key = result.data('orderby_meta_key');

                that.find('.wrap-load-more').show();

                var data_ajax   = {
                    action: 'ovabrw_search_ajax',
                    template: template,
                    start_date: start_date,
                    end_date: end_date,
                    adults: adults,
                    childrens: childrens,
                    beds: beds,
                    baths: baths,
                    icon_adults: icon_adults,
                    icon_children: icon_children,
                    icon_area: icon_area,
                    icon_beds: icon_beds,
                    icon_baths: icon_baths,
                    field_1: field_1,
                    field_2: field_2,
                    field_3: field_3,
                    field_4: field_4,
                    field_5: field_5,
                    order: order,
                    orderby: orderby,
                    orderby_meta_key: orderby_meta_key,
                    per_page: per_page,
                    paged: paged,
                    clicked: clicked,
                };

                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: data_ajax,
                    success:function(response) {
                        if( response ){
                            var json = JSON.parse( response );
                            var item = $(json.result).fadeOut(300).fadeIn(500);
                            result.html(item);

                            // update number results found
                            var number_results_found =  result.find('.room_number_results_found').val();

                            if ( number_results_found == undefined ) {
                                number_results_found = 0 ;
                            };

                            result.closest('.wrap-search-ajax').find('.number-result-room-found').html('').append( number_results_found  );

                            // hide icon loading ajax
                            that.find('.wrap-load-more').hide();

                            if ( paged != 1 ) {
                                result.find('.ovabrw-pagination-ajax .current').attr('data-paged',1);
                            }
                        }
                    },
                });
            }
        },

        ova_gallery_product: function() {

            $('.elementor-product-image').each( function() {
                video_popup($(this));
            });

            /* Room Video */
            function video_popup( that ) {
                var video = that.find('.room-video-link');
                var modal_container = that.find('.room-video-modal-container');
                var modal_close     = that.find('.modal-close');
                var modal_video     = that.find('.modal-video');

                video.each( function() {
                    $(this).on('click', function(){
                        var url = get_url( $(this).data('src') );
                        var option      = '?autoplay=1;&loop=1;&controls=1;&showinfo=1;&modestbranding=1';
                        if ( url != 'error' ) {
                            modal_video.attr('src', "https://www.youtube.com/embed/" + url + option );
                            modal_container.css('display', 'flex');
                        }
                    });
                });

                // close video
                modal_close.on('click', function() {
                    modal_container.hide();
                    modal_video.removeAttr('src');
                });

                // window click
                $(window).click( function(e) {
                    if ( e.target.className == 'room-video-modal-container' ) {
                        modal_container.hide();
                        modal_video.removeAttr('src');
                    }
                });
            }

            function get_url( url ) {
                var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                var match = url.match(regExp);

                if (match && match[2].length == 11) {
                    return match[2];
                } else {
                    return 'error';
                }
            }
        },

        ova_deposit: function() {
            $('form.booking_form .ovabrw-deposit .ovabrw-type-deposit').each( function() {
                var deposit = $(this).find('input[name="ova_type_deposit"]:checked').val();

                if ( deposit == 'full' ) {
                    $(this).closest('.ovabrw-deposit').find('.title-deposite').hide();
                } else {
                    $(this).closest('.ovabrw-deposit').find('.title-deposite').show();
                }
            });

            $('form.booking_form .ovabrw-deposit .ovabrw-type-deposit').on( 'change', function() {
                var deposit = $(this).find('input[name="ova_type_deposit"]:checked').val();

                if ( deposit == 'full' ) {
                    $(this).closest('.ovabrw-deposit').find('.title-deposite').hide();
                } else {
                    $(this).closest('.ovabrw-deposit').find('.title-deposite').show();
                }
            });
        },
        ova_remove_from_cart: function() {
            $(document).on( 'click', '.remove_from_cart_button', function() {
                var that = $(this);
                var cart_item_key = that.data( 'cart_item_key' );

                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: {
                        action: 'ovabrw_remove_cart',
                        cart_item_key: cart_item_key,
                    },
                    success:function(response) {
                        if ( response != '' ) {
                            $('.ova-menu-cart').find('.cart-total .items').html( response );
                        }
                    },
                });
            });
        },
    };

    /* ready */
    $(document).ready(function () {
        Brw_Frontend.init();
    });

    /* Show Custom Taxonomy in Search Form */
    function ovas_show_cus_tax( form, cat_val ) {
        if ( typeof ova_brw_search_show_tax_depend_cat != 'undefined' && form.length ) {
            if ( ova_brw_search_show_tax_depend_cat == 'yes' ) {
                /* Hide all custom taxonomy select */
                form.find('.s_field_cus_tax').hide();
            } else {
                form.find('.s_field_cus_tax').show();
            }
        }
        
        if ( cat_val ) {
            $.ajax({
                url: ajax_object.ajax_url,
                type: 'POST',
                data: ({
                   action: 'ovabrw_get_tax_in_cat',
                   cat_val: cat_val,
                }),
                success:function(response) {
                    if ( response ) {
                        var list_tax_values = response.split(",");

                        if ( list_tax_values.length ) {
                            for ( var i = 0; i < list_tax_values.length; i++ ) {
                                if ( form.find("."+list_tax_values[i]).length > 0 ) {
                                    form.find("."+list_tax_values[i]).show();
                                }
                            }
                        }
                    }
                },
            });
        }
    }

    document.addEventListener('DOMContentLoaded', function() {
        var date_format = '';

        if ( typeof brw_date_format !== 'undefined' ) {
            date_format = brw_date_format;
        }
        
        $('.entry-summary .wrap_calendar').each( function(e) {
            var srcCalendarEl   = $(this).find('.ovabrw__product_calendar')[0];
            if ( srcCalendarEl === null ) return;

            var nav             = srcCalendarEl.getAttribute('data-nav');
            var default_view    = srcCalendarEl.getAttribute('data-default_view');
            var first_day       = srcCalendarEl.getAttribute('data-first-day');

            if ( ! first_day ) {
                first_day = 0;
            }

            if ( default_view == 'month' ) {
                default_view = 'dayGridMonth';
            }
            
            var cal_lang            = srcCalendarEl.getAttribute( 'data-lang' ).replace(/\s/g, '');
            var define_day          = srcCalendarEl.getAttribute('data-define_day');
            var data_event_number   = parseInt( srcCalendarEl.getAttribute('data_event_number') );
            var default_hour_start  = srcCalendarEl.getAttribute( 'default_hour_start' );
            var time_to_book_start  = srcCalendarEl.getAttribute('time_to_book_start');
            time_to_book_start      = Brw_Frontend.ova_get_time_to_book_start(time_to_book_start);
            var price_calendar      = srcCalendarEl.getAttribute('price_calendar');
            price_calendar          = price_calendar.replace(/[\u0000-\u001F]+/g,""); 
            var price_full_calendar_value = JSON.parse( price_calendar );
            var special_time        = srcCalendarEl.getAttribute('data-special-time');
            var special_time_value  = JSON.parse( special_time );
            var background_day      = srcCalendarEl.getAttribute('data-background-day');
            var disable_week_day    = srcCalendarEl.getAttribute('data-disable_week_day');
            var disable_week_day_value = '';

            if ( disable_week_day ) {
                disable_week_day_value = JSON.parse( disable_week_day );
            }

            var events          = '';
            var date_rent_full  = [];
            var order_time      = srcCalendarEl.getAttribute('order_time');

            if ( order_time && order_time.length > 0 ) {
                events = JSON.parse( order_time );
            }

            if ( typeof events !== 'undefined' && events.length > 0 ) {
                events.forEach(function(item, index) {
                    if ( item.hasOwnProperty('rendering') ) {
                        date_rent_full.push( item.start );
                    }
                });
            }
            
            var srcCalendar = new FullCalendar.Calendar(srcCalendarEl, {
                editable: false,
                events: events,
                firstDay: first_day,
                height: 'auto',
                headerToolbar: {
                    left: 'prev,next,today,' + nav,
                    right: 'title',
                },
                initialView: default_view,
                locale: cal_lang,
                dayMaxEventRows: true, // for all non-TimeGrid views
                views: {
                    dayGrid: {
                        dayMaxEventRows: data_event_number
                        // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
                    },
                    timeGrid: {
                        dayMaxEventRows: data_event_number
                        // options apply to timeGridWeek and timeGridDay views
                    },
                    week: {
                        dayMaxEventRows: data_event_number
                        // options apply to dayGridWeek and timeGridWeek views
                    },
                    day: {
                        dayMaxEventRows: data_event_number
                        // options apply to dayGridDay and timeGridDay views
                    }
                },
                dayCellDidMount: function(e) {
                    var new_date    = new Date( e.date );
                    var time_stamp  = Date.UTC( new_date.getFullYear(), new_date.getMonth(), new_date.getDate() )/1000;
                    
                    if ( price_full_calendar_value != '' ) {
                        var type_price = price_full_calendar_value[0].type_price;

                        if ( type_price == 'day' ) {
                            var ovabrw_daily_monday     = price_full_calendar_value[1].ovabrw_daily_monday;
                            var ovabrw_daily_tuesday    = price_full_calendar_value[1].ovabrw_daily_tuesday;
                            var ovabrw_daily_wednesday  = price_full_calendar_value[1].ovabrw_daily_wednesday;
                            var ovabrw_daily_thursday   = price_full_calendar_value[1].ovabrw_daily_thursday;
                            var ovabrw_daily_friday     = price_full_calendar_value[1].ovabrw_daily_friday;
                            var ovabrw_daily_saturday   = price_full_calendar_value[1].ovabrw_daily_saturday;
                            var ovabrw_daily_sunday     = price_full_calendar_value[1].ovabrw_daily_sunday;

                            switch ( new_date.getDay() ) {
                                case 0: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }
                                    
                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ){
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_sunday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_sunday;
                                        }
                                        
                                        return e;    
                                    }
                                    
                                    break;
                                }
                                case 1: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_monday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_monday;
                                        }

                                        return e;
                                    }
                                    
                                    break;
                                }
                                case 2: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_tuesday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_tuesday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 3: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_wednesday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_wednesday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 4: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_thursday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_thursday;
                                        }

                                        return e;
                                    }
                                    break;
                                }
                                case 5: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_friday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;;
                                                    return e;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_friday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 6: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_saturday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_saturday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                            }
                        } else if ( type_price == 'hour' ) {
                            // check disable week day in settings
                            if ( disable_week_day_value ) {
                                $.each( disable_week_day_value, function( key, day_value ) {
                                    if( day_value == new_date.getDay() ) {
                                        e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                        // set background day
                                        $('.unavailable_date').css('background-color', background_day);
                                    }
                                });
                            }

                            var ovabrw_price_hour = price_full_calendar_value[1].ovabrw_price_hour;
                            let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                            if ( el ) {
                                if ( special_time_value ) {
                                    el.innerHTML = ovabrw_price_hour;
                                    $.each( special_time_value, function( price, special_timestamp ) {
                                        if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                            el.innerHTML = price;
                                        }
                                    });
                                } else {
                                    el.innerHTML = ovabrw_price_hour;
                                }
                                
                                return e;
                            }
                        } else if ( type_price == 'mixed' ) {
                            var ovabrw_daily_monday     = price_full_calendar_value[1].ovabrw_daily_monday;
                            var ovabrw_daily_tuesday    = price_full_calendar_value[1].ovabrw_daily_tuesday;
                            var ovabrw_daily_wednesday  = price_full_calendar_value[1].ovabrw_daily_wednesday;
                            var ovabrw_daily_thursday   = price_full_calendar_value[1].ovabrw_daily_thursday;
                            var ovabrw_daily_friday     = price_full_calendar_value[1].ovabrw_daily_friday;
                            var ovabrw_daily_saturday   = price_full_calendar_value[1].ovabrw_daily_saturday;
                            var ovabrw_daily_sunday     = price_full_calendar_value[1].ovabrw_daily_sunday;

                            switch( new_date.getDay() ) {
                                case 0: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }
                                    
                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_sunday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_sunday;
                                        }
                                        
                                        return e;    
                                    }
                                    break;
                                }
                                case 1: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_monday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_monday;
                                        }

                                        return e;
                                    }
                                    break;
                                }
                                case 2: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_tuesday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_tuesday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 3: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_wednesday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_wednesday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 4: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_thursday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_thursday;
                                        }

                                        return e;
                                    }
                                    break;
                                }
                                case 5: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_friday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;;
                                                    return e;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_friday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 6: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_saturday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_saturday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                            }
                        }
                    } else {
                        var type_price = srcCalendarEl.getAttribute('type_price');
                        // period_time
                        if ( type_price == 'period_time' || type_price == 'transportation' ) {

                            // check disable week day in settings
                            if ( disable_week_day_value ) {
                                $.each( disable_week_day_value, function( key, day_value ) {
                                    if( day_value == new_date.getDay() ) {
                                        e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                        // set background day
                                        $('.unavailable_date').css('background-color', background_day);
                                    }
                                });
                            }

                            return e;
                        }
                    }
                },
                dateClick: function( info ) {
                    var new_date_a = new Date( info.date );            

                    var year  = new_date_a.getFullYear();
                    var month = new_date_a.getMonth() + 1;
                    var day   = new_date_a.getDate();

                    month   = ( month < 10 ) ? '0' + month : month;
                    day     = ( day < 10 ) ? '0' + day : day;

                    var today = new Date();
                    var date_click_24 = new Date( year, month - 1, day, 24, 0 );

                    var date_check_rent_full = year + '-' + month + '-' + day;

                    if ( define_day == "hotel" || $('.rental_item input[name="ovabrw_pickup_date"]').hasClass('no_time_picker') || ( time_to_book_start.length < 1 ) ) {
                        default_hour_start = '';
                    }

                    // check disable week day in settings
                    if ( disable_week_day_value ) {
                        $.each( disable_week_day_value, function( key, day_value ) {
                            if ( day_value == new_date_a.getDay() ) {
                                alert(notifi_disable_day);
                                date_click_24 = 0;
                            }
                        });
                    }

                    if ( ( ! date_rent_full.includes( date_check_rent_full ) ) && ( date_click_24 >= today ) ) {
                        var date_input = date_format;
                        date_input = date_input.replace('Y', year);
                        date_input = date_input.replace('m', month);
                        date_input = date_input.replace('d', day);

                        if ( default_hour_start ) {
                            $('input[name="ovabrw_pickup_date"]').val(date_input + ' ' + default_hour_start);
                        } else {
                            $('input[name="ovabrw_pickup_date"]').val(date_input);
                        }
                        
                        if( document.getElementById("ovabrw_booking_form") ){
                            document.getElementById("ovabrw_booking_form").scrollIntoView({behavior: 'smooth'});
                            $('.startdate_perido_time').each( function() {
                                var that = $(this);
                                if ( that.val() ) {
                                    Brw_Frontend.ova_ajax_load_packages(that);
                                }
                            });
                        }
                    }
                }
            });

            srcCalendar.render();
        }); 
    }); 

    window.Brw_Frontend = Brw_Frontend;

})(jQuery);